import React, { useState } from "react";
import { NavLink, Link } from "react-router-dom";
import { FiAlignRight, FiXCircle, FiChevronDown } from "react-icons/fi";
import "./navbar.css";
import ButtonLanguage from "../ButtonLanguage/buttonLanguage.js";
import SwitchMode from "../Switch/Switch.tsx";
import { useTranslation } from "react-i18next";

const Navbarmenu = () => {
  const [isMenu, setisMenu] = useState(false);
  const [isResponsiveclose, setResponsiveclose] = useState(false);
  const toggleClass = () => {
    setisMenu(isMenu === false ? true : false);
    setResponsiveclose(isResponsiveclose === false ? true : false);
  };

  let boxClass = ["main-menu menu-right menuq1"];
  if (isMenu) {
    boxClass.push("menuq2");
  } else {
    boxClass.push("");
  }

  const [isMenuSubMenu, setMenuSubMenu] = useState(false);
  const [dropdownState, setDropdownState] = useState({});

  const toggleSubmenu = () => {
    setMenuSubMenu(isMenuSubMenu === false ? true : false);
  };

  const handleScrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  let boxClassSubMenu = ["sub__menus"];
  if (isMenuSubMenu) {
    boxClassSubMenu.push("sub__menus__Active");
  } else {
    boxClassSubMenu.push("");
  }

  const toggleDropdown = (dropdownName) => {
    setDropdownState((prevState) => ({
      ...prevState,
      [dropdownName]: !prevState[dropdownName],
    }));
  };

  const [t, i18next] = useTranslation("global");

  return (
    <div className="sticky">
      <header className="header__middle">
        <div className="container">
          <div className="row">
            <div
              className="header__middle__logo"
              style={{ display: "flex", alignItems: "center" }}
            >
              <NavLink
                exact="true"
                className="is-active"
                to="/"
                onClick={handleScrollToTop}
              >
                <div className="imgLogo"></div>
              </NavLink>
              <div className="actionsNav" style={{ marginLeft: "10px" }}>
                <ButtonLanguage />
              </div>
              <div className="actionsNav" style={{ marginLeft: "10px" }}>
                <SwitchMode />
              </div>
            </div>

            <div className="header__middle__menus">
              <nav className="main-nav ">
                {isResponsiveclose === true ? (
                  <>
                    <span
                      className="menubar__button"
                      style={{ display: "none" }}
                      onClick={toggleClass}
                    >
                      {" "}
                      <FiXCircle />{" "}
                    </span>
                  </>
                ) : (
                  <>
                    <span
                      className="menubar__button"
                      style={{ display: "none" }}
                      onClick={toggleClass}
                    >
                      {" "}
                      <FiAlignRight />{" "}
                    </span>
                  </>
                )}

                <ul className={boxClass.join(" ")}>
                  <li className="menu-item">
                    <NavLink
                      exact="true"
                      className="is-active"
                      onClick={toggleClass}
                      to={`/`}
                    >
                      {t("navbar.home")}
                    </NavLink>
                  </li>
                  <li className="menu-item sub__menus__arrows">
                    {" "}
                    <Link to="#">
                      vACC
                      <FiChevronDown />{" "}
                    </Link>
                    <ul
                      className={
                        dropdownState["dropdown1"]
                          ? "sub__menus__Active"
                          : "sub__menus"
                      }
                    >
                      <li>
                        {" "}
                        <NavLink className="is-active" to={`/staff`}>
                          Staff
                        </NavLink>{" "}
                      </li>
                      <li>
                        <NavLink className="is-active" to={`/mentores`}>
                          {t("navbar.mentors")}
                        </NavLink>{" "}
                      </li>
                      <li>
                        <NavLink
                          className="is-active"
                          to={`/examinadores`}
                        >
                          {t("navbar.examiners")}
                        </NavLink>{" "}
                      </li>
                    </ul>
                  </li>
                  <li className="menu-item">
                    <NavLink
                      exact="true"
                      className="is-active"
                      onClick={toggleClass}
                      to={`/pilotos`}
                    >
                      {t("navbar.pilots")}
                    </NavLink>
                  </li>
                  <li className="menu-item sub__menus__arrows">
                    {" "}
                    <Link to="#">
                      {t("navbar.controllers")}
                      <FiChevronDown />{" "}
                    </Link>
                    <ul
                      className={
                        dropdownState["dropdown2"]
                          ? "sub__menus__Active"
                          : "sub__menus"
                      }
                    >
                      <li>
                        <NavLink
                          className="is-active"
                          to={`/controladores`}
                        >
                          {t("controllers.active")}
                        </NavLink>{" "}
                      </li>
                      <li>
                        <NavLink className="is-active" to={`/visitantes`}>
                          {t("controllers.visitor")}
                        </NavLink>{" "}
                      </li>
                      <li>
                        <NavLink className="is-active" to={`/solo`}>
                          Solo Validation
                        </NavLink>{" "}
                      </li>
                      <li>
                        <NavLink className="is-active" to={`/lista`}>
                          {t("controllers.listaFormacion")}
                        </NavLink>{" "}
                      </li>
                      <li>
                        <NavLink
                          className="is-active"
                          to={`/listaExpress`}
                        >
                          {t("controllers.listaFormacionExpress")}
                        </NavLink>{" "}
                      </li>
                      <li>
                        <NavLink className="is-active" to={`/tiers`}>
                          Tier 2
                        </NavLink>{" "}
                      </li>
                    </ul>
                  </li>
                  <li className="menu-item sub__menus__arrows">
                    {" "}
                    <Link to="#">
                      {t("navbar.events")}
                      <FiChevronDown />{" "}
                    </Link>
                    <ul
                      className={
                        dropdownState["dropdown3"]
                          ? "sub__menus__Active"
                          : "sub__menus"
                      }
                    >
                      <li>
                        {" "}
                        <NavLink className="is-active" to={`/eventos`}>
                          {t("navbar.events")}
                        </NavLink>{" "}
                      </li>
                      <li>
                        <NavLink className="is-active" to={`/cpts`}>
                          CPTs
                        </NavLink>{" "}
                      </li>
                    </ul>
                  </li>
                  <li className="menu-item">
                    <NavLink
                      exact="true"
                      className="is-active"
                      onClick={toggleClass}
                      to={`https://biblioteca.vatsimspain.es/`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {t("navbar.wiki")}
                    </NavLink>
                  </li>
                  <li className="menu-item">
                    <NavLink
                      exact="true"
                      className="is-active"
                      onClick={toggleClass}
                      to={`https://dashboard.vatsimspain.es`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <div className="dashButton">Dashboard</div>
                    </NavLink>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </header>
    </div>
  );
};

export default Navbarmenu;
