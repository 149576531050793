// Modules
import React from "react";
import { Routes, Route, BrowserRouter } from "react-router-dom";
import ScrollRestoration from "./components/ScrollToTop/ScrollToTop";
import NavBar from "./components/NavBar/navbar.js";
import Footer from './components/Footer/footer.js'
import CookieConsent from './components/Cookies/Cookies.js'
import Loading from './components/LoadingScreen/LoadingScreen.js'
import CraftedWithLove from "./components/CraftedWithLove.js";


// CSS
import './css/App.css';
import "./css/fade.css";

// Pages
import Home from './pages/HomePage.js'
import StaffPage from "./pages/StaffPage.js";
import ControllersPage from "./pages/ControllersPage.js";
import SoloPage from './pages/SoloPage.js'
import VisitorPage from './pages/VisitorPage.js'
import EventsPage from "./pages/EventsPage.js";
import MentorsPage from './pages/MentorsPage.js'
import ExaminersPage from './pages/ExaminersPage.js'
import WaitingList from './pages/ListaEspera.js'
import WaitingListExpress from './pages/ListaExpress.js'
import CPTSPage from './pages/CPTPage.js'
import PilotsPage from './pages/PilotsPage.js'
import NotFoundPage from './pages/NotFound.js'
import TeamsPage from './pages/TeamsPage.js'
import TiersPage from './pages/TiersPage.js'

function App() {

  return (
    <div className="App">
      <BrowserRouter>
        <ScrollRestoration />
        <NavBar />
        <Routes>
          <Route path="/" element={<div> <Loading /> <CookieConsent /> <Home/> </div>} />
          <Route path="staff" element={<StaffPage />} />
          <Route path="mentores" element={<MentorsPage />} />
          <Route path="examinadores" element={<ExaminersPage />} />
          <Route path="controladores" element={<ControllersPage/>} />
          <Route path="solo" element={<SoloPage/>} />
          <Route path="lista" element={<WaitingList />} />
          <Route path="listaExpress" element={<WaitingListExpress />} />
          <Route path="visitantes" element={<VisitorPage/>} />
          <Route path="eventos" element={<EventsPage />} />
          <Route path="cpts" element={<CPTSPage />} />
          <Route path="pilotos" element={<PilotsPage />} />
          <Route path="teams" element={<TeamsPage />} />
          <Route path="tiers" element={<TiersPage/>} />
          <Route path='*' element={<NotFoundPage />}/>
        </Routes>
        <CraftedWithLove />
        <Footer />
      </BrowserRouter>
    </div>
  );
}

export default App;
